<template>
  <div>
    <el-menu
      :default-active="defaultActive"
      active-text-color="#00A0E9"
      text-color="rgba(55, 69, 103, 0.65)"
      mode="horizontal"
      @select="changeTab"
    >
      <el-menu-item index="1">手机验证登录</el-menu-item>
      <el-menu-item index="2">账户密码登录</el-menu-item>
    </el-menu>
    <div class="login-input">
      <phone-login v-if="defaultActive == 1"></phone-login>
      <password-login v-if="defaultActive == 2"></password-login>
    </div>
  </div>
</template>

<script>
import { getCode, codeLogin } from "@/api/user";
import { PasswordLogin, PhoneLogin } from "./index";
export default {
  data() {
    return {
      flag: true,
      checked: true,
      defaultActive: "1",
    };
  },
  components: {
    PhoneLogin,
    PasswordLogin,
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.params.id) {
          this.defaultActive = val.params.id;
        }
      },
    },
  },
  methods: {
    changeTab(val) {
      this.$router.replace({ name: "loginChild", params: { id: val } });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-input {
  margin-top: 24px;
}
.el-menu-item {
  padding: 0;
  margin-right: 32px;
  height: 32px;
  line-height: 32px;
}

.is-active {
  border-bottom-color: #8ec31f !important;
}
.el-menu.el-menu--horizontal {
  border-bottom-color: transparent;
}
.login-input-item {
  margin-bottom: 24px;
}
</style>